import { Outlet, Navigate, useLocation } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

export const RequireAuth = () => {
  const { auth } = useAuth();
  const location = useLocation();
  return (
    auth?.user
      ? <Outlet />
      : <Navigate to='/auth/login' state={{ from: location }} replace />
  )
}

export const RequireAdmin = () => {
  const { auth } = useAuth();
  const location = useLocation();
  return (
    auth?.user && auth?.user?.designation === 'admin'
      ? <Outlet />
      : <Navigate to='/unauthorized' state={{ from: location }} replace />
  )
}
