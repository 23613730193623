import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/home';
import Auth from './pages/auth';
import { RequireAuth, RequireAdmin } from './helpers/requireAuth';
import Admin from './pages/admin';
import Unauthorized from './pages/unauthorized';
import Game from './pages/game';
import { CartProvider } from './context/cart';
import ScrollToTop from './components/ScrollToTop';
import Cart from './pages/cart';
import GameCategory from './pages/gameCategory';

function App() {
  return (
    <div className="App">
      <CartProvider>
        <Router>
          <ScrollToTop>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/games/:gameId' element={<Game />} />
              <Route path='/categories/:category' element={<GameCategory />} />
              <Route path='/cart' element={<Cart />} />
              <Route element={<RequireAuth />}>
                <Route path='/unauthorized' element={<Unauthorized />} />
              </Route>
              <Route element={<RequireAdmin />}>
                <Route path='/admin/*' element={<Admin />} />
              </Route>
              <Route path='/auth/*' element={<Auth />} />
            </Routes>
          </ScrollToTop>
        </Router>
      </CartProvider>
    </div>
  );
}

export default App;
