import React, { useEffect, useState } from 'react'
import UserLayout from '../layouts/user'
import { useParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { getProduct } from '../api/services'
import Breadcrumb from 'rsuite/Breadcrumb'
import ProductItem from '../components/product-item'

const Game = () => {
  const [game, setGame] = useState([])
  const params = useParams();
  const gameId = params?.gameId;
  useEffect(() => {
    const fetchData = async () => {
      const result = await getProduct(gameId);
      setGame(result?.data);
    }
    if (gameId) {
      if (isEmpty(game)) {
        fetchData()
      }
      if (!isEmpty(game) && game?._id !== gameId) {
        fetchData()
      }
    }
  }, [game, gameId])
  return (
    <UserLayout>
      <div className='game-details-page max-w-968 mlr-auto'>
        <div className='py-3'>
          <Breadcrumb className='text-white text-base'>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>{game && game?.title}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <section className='game-details'>
          <ProductItem product={game} />
        </section>
      </div>
    </UserLayout>
  )
}

export default Game