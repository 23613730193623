import Slider from 'react-slick';

const SliderComponent = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };
  return (
    <section className='mt-3'>
      <Slider {...settings}>
        <div className='relative game-slider-poster rounded-sm'>
          <img className='absolute object-cover top-0 left-0 h-full w-full' src='/assets/images/1.jpg' alt='gaming poster' />
        </div>
        <div className='relative game-slider-poster'>
          <img className='absolute object-cover top-0 left-0 h-full w-full' src='/assets/images/2.jpg' alt='gaming poster' />
        </div>
        <div className='relative game-slider-poster'>
          <img className='absolute object-cover top-0 left-0 h-full w-full' src='/assets/images/3.jpg' alt='gaming poster' />
        </div>
      </Slider>
    </section>
  )
}

export default SliderComponent