import { useEffect, useState } from 'react'
import ProductItem from './product-item'
import { getProducts } from '../api/services'
import isEmpty from 'lodash/isEmpty'

const ProductGrid = () => {
  const [products, setProducts] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const result = await getProducts();
      setProducts(result?.data)
    }
   fetchData()
  }, [])
  return (
    <>
      <h1 className="text-2xl font-extrabold mt-8 mb-8 text-gray-100 leading-9 uppercase tracking-tight">
        Best sellers
      </h1>
      <div className='products-grid grid gap-5 mt-4'>
        {isEmpty(products) ? <h1>No products in database</h1>
          : products?.map(product => (
            <ProductItem homepage={true} key={product._id} product={product} />
          ))
        }

      </div>
    </>
  )
}

export default ProductGrid;