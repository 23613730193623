import React, { useContext } from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import { CartContext } from '../context/cart'
import { isEmpty } from 'lodash'
import Badge from 'rsuite/Badge'
import { Link } from 'react-router-dom'
import { FaCartPlus } from 'react-icons/fa'

const UserLayout = ({ children }) => {
  const cart = useContext(CartContext)
  return (
    <>
      <Navbar />
      <main className='h-full user-layout py-3 px-1 relative'>
        {children}
        {!isEmpty(cart?.items) &&
          <div className='fixed bg-white right-4 bottom-4 cart-circle flex items-center justify-center'>
            <Link to='/cart' 
              className='flex items-center w-full h-full justify-center cursor-pointer'>
              <Badge content={cart?.items?.length}>
                <FaCartPlus
                  className='hover:text-gray:400'
                  size={'24px'}
                />
              </Badge>
            </Link>
          </div>
        }
      </main>
      <Footer />
    </>
  )
}

export default UserLayout