import { useContext } from 'react';
import { Link } from 'react-router-dom'
import { CartContext } from '../context/cart';

// public image path
const PIU = '/assets/images'
// image array to later select random image path
export const imageArray = [`${PIU}/fifa23.jpg`, `${PIU}/mdw2.jpg`];
export const getRandomImage = () => {
  return imageArray[Math.floor(Math.random() * imageArray.length)];
}
const ProductItem = ({ product, homepage }) => {
  const cart = useContext(CartContext);
  if (homepage) {
    return (
      <div className='product-item'>
        <Link to={`/games/${product?._id}`}>
          <div className='gameThumbnail relative rounded-lg shadow-lg overflow-hidden'>
            <img className='absolute object-left h-full w-full top-0 left-0 object-cover' src={getRandomImage(imageArray)} alt='game poster' />
          </div>
        </Link>
        <div className="h-50 bg-rgb-12-0-32 rounded-b-lg flex flex-col py-2 items-center text-gray-100 justify-between px-4">
            <Link className='text-white hover:text-gray-200 text-center' to={`/games/${product?._id}`}>
            <div className='text-base'>{product?.title}</div>
            <div className="border-r border-gray-300 pr-4 mr-4"></div>
            <div className='mt-3 mb-3'>RWF {product?.price}</div>
            </Link>
            <button
              onClick={() => cart.addOneToCart(product)}
              className="block w-full py-2 px-4 rounded-md bg-pink-700 text-white font-bold hover:bg-pink-700 focus:outline-none focus:shadow-outline-orange">
              Add to cart
            </button>
        </div>
      </div>
    )
  }
  // view on the individual game page
  return (
    <div className='product-item grid grid-cols-2'>
      <div className='gameThumbnail gameThumbnailLarge relative rounded-lg shadow-lg overflow-hidden'>
        <img className='absolute object-left h-full w-full top-0 left-0 object-cover' src={getRandomImage(imageArray)} alt='game poster' />
      </div>
      <div className="h-50 bg-rgb-12-0-32 game-details-container rounded-b-lg py-5 items-center text-gray-100 justify-between px-4">
        <div className='text-3xl font-bold uppercase'>
          {product?.title}
        </div>
        <div className="border-r border-gray-300 pr-4 mr-4"></div>
        <div className='mt-3 mb-3 text-pink text-2xl'>RWF {product?.price}</div>
        <p>{product?.description}</p>
        <p className='text-gray:300 uppercase mt-4'>In stock</p>
        <button
          onClick={() => cart.addOneToCart(product)}
          className="mt-6 py-2 px-4 rounded-md bg-pink-700 text-white font-bold hover:bg-pink-700 focus:outline-none focus:shadow-outline-orange">
          Add to Cart
        </button>
      </div>
    </div>
  )
}
export default ProductItem;