import {createContext, useState} from 'react'
import isNil from 'lodash/isNil'

const AuthCtx = createContext();

export const AuthProvider = ({children}) => {
  const [auth, setAuth] = useState(() => {
    const localData = localStorage.getItem('user');
    const token = localStorage.getItem('token')
    if (isNil(localData) || isNil(token)) return {};
    return { user: JSON.parse(localData), token: JSON.parse(token) };
  })
  return (
    <AuthCtx.Provider value={{ auth, setAuth }}>
      {children}
    </AuthCtx.Provider>
  )
}

export default AuthCtx;