import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import UserLayout from '../layouts/user';

const Unauthorized = () => {
  const { auth } = useAuth();
  const navigate = useNavigate()
  useEffect(() => {
    if (auth?.user && auth?.user?.designation === 'admin') {
      return navigate('/admin', { replace: true})
    }
  })
  return (
    <UserLayout>
      <div className="flex items-center justify-center flex-col">
        <div className="p-6 space-y-6 max-w-sm w-full mt-10 text-center rounded-md auth-card-bg">
          <h1 className="text-2xl font-extrabold text-gray-100 leading-9 tracking-tight">
            Unauthorized
          </h1>
          <Link className='underline text-white mt-5' to='/'>Back home</Link>
        </div>
      </div>
    </UserLayout>
  )
}

export default Unauthorized;