import React, { useContext } from 'react'
import UserLayout from '../layouts/user'
import { CartContext } from '../context/cart';
import { Row, Grid, Col, Panel, Divider } from 'rsuite'
import { isEmpty } from 'lodash';
import { FaShoppingCart } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { HOMEPAGE_URL } from '../constants/paths';

const Cart = () => {
  const cart = useContext(CartContext);
  const products = cart?.items;
  const navigate = useNavigate()
  if (isEmpty(products)) {
    return (
      <UserLayout>
        <div className="px-2 py-5 max-w-968 mlr-auto h-full w-full items-center justify-center flex">
          <Panel 
            className='bg-white mt-5 empty-cart flex items-center text-center flex-col justify-center'>
            <FaShoppingCart size={28} className='mlr-auto' />
            <p className='font-bold mt-4'>Your cart is empty</p>
            <p>Select games to add to cart</p>
            <button
              onClick={()=>navigate(HOMEPAGE_URL)}
              className="text-white w-full mt-3 cursor-pointer hover:bg-pink-700 bg-pink-700 py-2 px-4" >
              View Games
            </button>
          </Panel>
        </div>
      </UserLayout>
    )
  }
  return (
    <UserLayout>
      <div className="px-2 py-5 max-w-968 mlr-auto">
        <h2 className='text-white text-2xl font-bold mb-4'>My Cart</h2>
        <Grid>
          <Row>
            <Col xs={24} sm={24} md={16}>
              {products.map((product) => (
                <Panel shaded bordered bodyFill className='bg-white shadow-sm mb-3 py-5 px-3'>
                  <Row gutter={16}>
                    <Col md={6}>
                      <img 
                        alt='game poster'
                        src="https://via.placeholder.com/240x240" height="240" />
                    </Col>
                    <Col md={18} className='flex flex-col h-full justify-center'>
                      <p className='uppercase font-bold text-lg'>{product?.title}</p>
                      <p>
                        <small>
                          {product?.description}
                        </small>
                      </p>
                    </Col>
                  </Row>
                </Panel>
              ))}
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Panel className='bg-white'>
                <p className='uppercase text-lg font-bold'>total</p>
                <Divider />
                <div className='product-pricing'>
                  <div className='flex justify-between items-center mb-2'>
                    <p className='font-bold uppercase'>Subtotal</p>
                    <small>RWF {cart.getTotalCost()}</small>
                  </div>
                  <div className='flex justify-between items-center mb-2'>
                    <p className='font-bold uppercase'>total</p>
                    <small>RWF {cart.getTotalCost()}</small>
                  </div>
                </div>
                <Divider />
                <div className="text-center my-5">
                  <button className="text-white w-full rounded-md cursor-pointer hover:bg-pink-700 bg-pink-700 py-2 px-4" >
                    Checkout
                  </button>
                </div>
                <div className='panel-footer'>
                  <p className='uppercase font-bold'>we accept</p>
                  <img src='/assets/images/payment.png' alt='American Express, VISA, VISA Electron, VISA Debit, VISA Carte Bleue, VISA Electron Carte Bleue, VISA Debit Carte Bleue, VISA Purchasing, Maestro, Mastercard, Debit Mastercard, PayPal,' />
                </div>
              </Panel>
            </Col>
          </Row>
        </Grid>
      </div>
    </UserLayout>
  )
}

export default Cart