import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
const AuthLayout = ({ children }) => {
  return (
    <>
      {children}
      <ToastContainer autoClose={1000} />
    </>
  )
}

export default AuthLayout;