// Login.js

import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom'
import { registerUser } from '../api/services';
import { toast } from 'react-toastify'

const Register = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const handleEmailChange = (setEmail) => (event) => {
    setEmail(event.target.value);
  };
  const handleUsernameChange = (setUsername) => (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (setPassword) => (event) => {
    setPassword(event.target.value);
  };

  const toggleShowPassword = (showPassword) => () => {
    setShowPassword(!showPassword);
  };

  const handleRegister = async (e) => {
    e.preventDefault()
    // validate email and password and log the user in
    const reqData = { email, password, username };
    const res = await registerUser(reqData);
    if (res?.data) {
      toast.success('Your account has been successfully created.')
      setTimeout(()=>{
        return navigate('/auth/login')
      }, 1000)
    } else {
      // const { response: { data } } = res;
      console.log('data', res.message)
      return toast.error(res.message)

    }

  };
  return (
    <div className="flex items-center justify-center h-screen flex-col">
      <div className='site-brand text-white text-2xl mb-3'>
        <Link to="/" className='text-center'>
          <img className='site_logo' src="/assets/images/logo/logo_official.png" alt="logo" />
        </Link>
      </div>
      <div className="p-6 space-y-6 max-w-sm w-full rounded-md auth-card-bg">
        <h1 className="text-2xl font-extrabold text-gray-100 leading-9 tracking-tight">
          Register
        </h1>
        <form className="space-y-6">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-100 opacity-70 leading-5"
          >
            Email address
          </label>
          <div className="relative rounded-md shadow-sm">
            <input
              id="email"
              type="email"
              required
              value={email}
              onChange={handleEmailChange(setEmail)}
              className="form-input py-3 text-white px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900"
            />
          </div>
          <label
            htmlFor="username"
            className="block text-sm font-medium text-gray-100 opacity-70 leading-5"
          >
            Username
          </label>
          <div className="relative rounded-md shadow-sm">
            <input
              id="username"
              type="username"
              required
              value={username}
              onChange={handleUsernameChange(setUsername)}
              className="form-input py-3 text-white px-4 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900"
            />
          </div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-100 opacity-70 leading-5"
          >
            Password
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="relative rounded-md shadow-sm">
              <input
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange(setPassword)}
                className="form-input py-3 px-4 block w-full text-white leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 bg-gray-900"
                required
              />
            </div>
            <div className="absolute inset-y-0 z-50 right-0 pr-3 flex items-center">
              <button
                type="button"
                onClick={toggleShowPassword(showPassword)}
                className="px-4 cursor-pointer py-2 font-medium text-gray-100 bg-gray-900 rounded-md hover:bg-gray-800 focus:outline-none focus:bg-gray-800 transition duration-150 ease-in-out"
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </button>

            </div>
          </div>
          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              onClick={handleRegister}
              className="px-4 py-2 font-medium text-white bg-gray-900 rounded-md hover:bg-gray-800 focus:outline-none focus:bg-gray-800 transition duration-150 ease-in-out"
            >
              Register
            </button>
          </div>
          <div className='login-cta text-white text-sm justify-end flex'>
            <Link to='/auth/login'>Already have an account? <span className='underline'>Sign in</span></Link>
          </div>
        </form>
      </div>
    </div>
  );

}

export default Register;