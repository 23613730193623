import React from 'react'
import UserLayout from '../layouts/user'
import {Routes, Route} from 'react-router-dom'

const Admin = () => {
  return (
    <UserLayout>
      <Routes>
        <Route path='' element={<AdminDashboard />} />
      </Routes>
    </UserLayout>
  )
}

const AdminDashboard = () => {
  return (
    <h1>hi</h1>
  )
}
export default Admin