import React from 'react'
import { Routes, Route } from 'react-router-dom'
import AuthLayout from '../layouts/auth'
import Login from './login'
import Register from './register'

const Auth = () => {
  return (
    <AuthLayout>
      <Routes>
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
      </Routes>
    </AuthLayout>
  )
}

export default Auth