import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Badge from 'rsuite/Badge'
import Dropdown from 'rsuite/Dropdown'
import { FaUser, FaCartPlus } from 'react-icons/fa'
import { CartContext } from '../context/cart'
import useAuth from '../hooks/useAuth'
import { LOGIN_URL } from '../constants/paths'

const Navbar = () => {
  const cart = useContext(CartContext);
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { setAuth } = useAuth()
  const handleLogout = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    setAuth({})
    return navigate('/auth/login')
  }
  const renderUserIcon = (props, ref) => {
    return <FaUser {...props} ref={ref} className='cursor-pointer mr-3' size={'24px'} />
  }
  return (
    <nav className='navigation'>
      <div className='nav-container p-2 flex items-center justify-between flex-wrap '>
        <div className='site-brand text-white text-2xl'>
          <Link to="/" className='text-center'>
            <img className='site_logo' src="/assets/images/logo/logo_official.png" alt="logo" />
            <p className='text-pink brand-title uppercase'>Store</p>
          </Link>
        </div>
        <div className='site-options text-white flex items-center '>
          <Link to='/cart' className='cursor-pointer flex items-center mr-5' >
            <Badge content={cart?.items?.length}>
              <FaCartPlus
                className='hover:text-gray-200' size={'24px'} />
            </Badge>
          </Link>
          {auth?.user ?
            <Dropdown className='px-4 py-4' renderToggle={renderUserIcon}>
                <p className='text-black text-center font-bold mb-2'>@{auth?.user?.username}</p>
                <Dropdown.Item>Profile</Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown>

            :
            <Link to='/auth/login' className='mr-5'>
              <button 
                onClick={()=>navigate(LOGIN_URL)}
                className='uppercase bg-pink-700 rounded-sm text-white py-2 px-4'>sign in
              </button>
            </Link>
          }

        </div>
      </div>
      <div className='subnavigation font-bold text-white uppercase text-sm flex justify-evenly items-center p-2'>
        <Link to='/categories/playstation'>Playstation</Link>
        <Link to='/categories/pc'>PC</Link>
        <Link to='/categories/xbox'>Xbox</Link>
        <Link to='/categories/nintendo'>Nintendo</Link>
        <Link to='/categories/new'>New</Link>
        <Link to='/categories/coming-soon'>Coming Soon</Link>
      </div>
    </nav>
  )
}

export default Navbar