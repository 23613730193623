import { isNil } from 'lodash';
import api from './axios'
import lowerCase from 'lodash/lowerCase';

// get list of products
export const getProducts = async () => {
  try {
    const result = await api.get('/products');
    return result
  } catch (err) {
    return err?.response?.data
  }
}
// get product details
export const getProduct = async (productId) => {
  try {
    const result = await api.get(`/products/${productId}`);
    return result;
  } catch (err) {
    return err?.response?.data
  }
}

// get products by category
export const getProductsByCategory = async (category) => {
  try {
    let products = [];
    const result = await getProducts();
    if (result.data) {
      // eslint-disable-next-line array-callback-return
      result.data.map(item => {
        if (item?.category.some(section => lowerCase(category) === lowerCase(section))) {
          products.push(item)
        }
      })
    }
    return products;
  } catch (err) {
    return err?.response?.data
  }
}
// login a user
export const loginUser = async (data) => {
  try {
    const result = await api.post('/users/login', data);
    return result;
  } catch (err) {
    return err?.response?.data
  }
}
// register a user
export const registerUser = async (data) => {
  try {
    const result = await api.post('/users/register', data);
    return result;
  } catch (err) {
    return err?.response?.data
  }
}

// persist to localstorage
export const persistUser = async (data) => {
  try {
    if (isNil(data)) return data
    const { token } = data;
    const userData = { id: data?._id, username: data?.username, email: data?.email, designation: data?.designation }
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('token', JSON.stringify(token))
  } catch (err) {
    console.err(err);
    // TODO: save error to error context
  }
}

