import React from 'react'
import ProductGrid from '../components/product-grid'
import Slider from '../components/slider'
import UserLayout from '../layouts/user'

const Home = () => {
  return (
    <UserLayout>
      <Slider />
      {/* best sellers product grid */}
      <ProductGrid />
    </UserLayout>
  )
}

export default Home