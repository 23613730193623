import React, { useEffect, useState } from 'react'
import UserLayout from '../layouts/user'
import { useParams } from 'react-router'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import upperCase from 'lodash/upperCase'
import { getProductsByCategory } from '../api/services'
import ProductItem from '../components/product-item'

const GameCategory = () => {
  const [products, setProducts] = useState([])
  const params = useParams();
  const category = params?.category;
  const categoryList = ['playstation', 'xbox', 'pc', 'nintendo'];
  const isInList = categoryList.find(item => item === category);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getProductsByCategory(category);
      setProducts(result)
    }
      fetchData()
  }, [category])
  if (isNil(isInList)) {
    return (
      <UserLayout>
        <p>Sorry that category can't be found</p>
      </UserLayout>
    )
  }

  return (
    <UserLayout>
      <h1 className="text-2xl font-extrabold mt-8 mb-8 text-gray-100 leading-9 uppercase tracking-tight">
        {upperCase(category)} Games
      </h1>
      <div className='products-grid grid gap-5 mt-4'>
        {isEmpty(products) ? <h1>No products in database</h1>
          : products?.map(product => (
            <ProductItem homepage={true} key={product._id} product={product} />
          ))
        }

      </div>
    </UserLayout>
  )
}

export default GameCategory